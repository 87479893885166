export default {
  type: "theme",
  version: 1,
  id: "1",
  default: true,
  config: {
    whitelabel:{
      clientName:"hdfc",
      logo:{
        height: "45px",
        width: "200px",
        marginLeft:"0px",
      },
      logoImage:"hdfc_logo.png",
      brandColor:"#E30613",
      urlColor: "#E30613",    
      common_background_color: "00AE8E",
      festiveTreats:true,
      components:{
        filterModal:{
          textColor: "#262626",
          dropdownColor :"rgba(227, 6, 19, 1)"
        },
        siteloader_img:"site-loader-adib.png",         
        siteLoader:{
          counterColor:"rgb(109, 192, 237)",
          bgColor:"linear-gradient(170.25deg, #E7F5FC -46.35%, #FFFFFF 47.06%)",
        },
        button:{
          backgroundColor: "#000811",  //should be same as primary color of app
          color: "#fff",
        },
        card: {
          arrowColor:"#E30613"
        },
        itemsListHeadingColor:"#000811",
        merchant_card:{
          offer_type:{
            backgroundColor: "#FFFBDC",
            color:"rgba(227, 6, 19, 1)",
            zIndex:1
          }, //same as merchant_img under merchant_details
          expiry_offer_type:{
            backgroundColor: "#B5B5B5",
            color:"rgb(0, 8, 17,0.5)",
            zIndex:1
          },
          cardCategoryColor: "#727272",
          cardTitleColor: "#000811",
          cardDescColor: "#001122",
          cardLearnMoreColor: "#469dde",
          cardExpire: "#001122",
          detailBg:false,
        }   
      },
      login: {
        loginBackground: "rgba(255, 251, 220, 1)",
        loginLeftLogo: "hdfc_login_popup",
        loginGuest:"rgba(227, 6, 19, 1)"
      },
      searchpage:{
        searchTitleText: "#000811",
        searchPageMap:"rgba(0, 67, 136, 1)",
      },
      header:{
        navbar: {
          categoryColor: {color:"#000811"},
          //categoryColor_active:{color: "#00337F",borderBottom: "2px solid #FF0000"},
          categoryColor_mbl:{color: "#000811"},
          categorybgColor_mbl: "#E30613",
          category_mbl_activeColor:"#FFF",
          categoryColorActive: "#E30613"
        },
        Banner: {
          color:"#E30613"
        },
        bg: "#FFE100",
        lineColor: "rgba(255, 255, 255,0.5)",
        locationText: "#000",
        secondoryImage: false,
        background: "rgba(255, 225, 0, 1)",
        menuBackground:"rgba(255, 253, 230, 1)"
      },
      homepage:{
        default_banner_image:"banner-en.png",
        default_banner_text_color:"#333",
        banner_bg_color: "#004388" ,  
      },
      cookiepopup: {
        headingcolor: "rgba(227, 6, 19, 1)",
      },
      merchant_details:{      
        merchant_img:{
          backgroundColor: "#FFFBDC",
          color:"#E30613",
        },
        merDes:"rgb(227, 6, 19)",
        //merchant_imgBorder: "1px solid #ECECEC",
        merchant_tile:{
          // backgroundColor:"rgba(255, 252, 213, 1)",

          color: "#000",
          // backgroundImage:`url("../../assets/img/merchant_details_page_bg.png")`,
        },
        merchant_details: {
          backgroundRepeat: 'repeat',
          backgroundSize: 'auto', /* You can use 'cover' or specific dimensions if you need */
          backgroundPosition: 'center',
        },
        offer_cards:{
          offerCardTitleColor:"#44444",
          offerCardInfoColor:"#001122",
          offerCardExpand:"#025296", 
          offerCardMenu:{
            color: "#000811",
          },
          cardofferinfo:"rgba(255, 252, 213, 0.6)",
          offerCardMenuActive:{
            color: "#004388",
            borderBottom: "2px solid #005AB7",
          },
          offerCardDescriptionTextColor:"#001122"
        },
        redeem_card:{
          bgColor:"#FFDD00", 
          headText:"#001122",
          redeem_now:{
            backgroundColor:"#004A8D",
            color:"#FFFFFF"
          },
          offerText:{
            color:"#001933",
            borderColor:"rgba(0, 25, 51,0.1)",
            validDate: "#001933"
          }
        },
        redeem_modal:{
          redeem_button:{
            backgroundColor:"#000811",
            color:"#fff",
          },
          headColor: "#000811",
          capColor: "#727272",
          codeColor: "#004388",
          codeTextColor:"#727272"
        },
        detailBg:true,
      },
      wishlistedpage:{
        nodata_heading: "#000811",
        nodata_text:"#727272",
        nodata_button: "rgba(0, 67, 136, 1)"
      },
      profile:{
        titleText:{color:"#000811", freqTitleColor: "#444444"},
        descriptionText:{color:"#616161", stillHaveQuesColor: "#001122"},
        profile_menu:{
          color:' #001122'
        },
        profile_menu_ar:{
          color:'#01347A'
        },
        profile_menu_active:{
          backgroundColor: "#FFFBDC",
          borderLeft: "3px solid #E30613",
          color: "#E30613"
        },
        profile_menu_act_ar: {
          borderRight: "3px solid #01347A",
          color: "#01347A"
        },
        claimed_offer:{
          claimed_button:{
            color: "#FFF",
            background:"#E30613"
          },
          card_expire: { color:"#616161"},
          nodata_hea: "#727272"
        },
        redemptions:{
          description_title_text:{color:"#444444"},
          description_title_value:{color:"#025296"},
          table_header:{color:"#025296"},
          table_content:{color:"#444444"},
          table_transid:{color:"#868686"}
        },
        faq:{
          questionColor: "#E30613",
          goToHomeColor:"rgba(227, 6, 19, 1)"
        }
      },
      footer:{
        background:"rgba(255, 251, 220, 1)",
        heading:{color:"rgba(255, 221, 0, 1)"},
        description:"#444444",
        foottext: "#000",
        backgroundColor1:"rgba(255, 221, 0, 1)",
      }
    }
  }
};