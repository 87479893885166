/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Footer Component
import React, { PureComponent } from "react";

import Image from "../Image";
import Icons from "../Icons";

import GooglePlay from "../../assets/img/google_play.png";
import AppImage from "../../assets/img/app_store.png";
import contact from "../../assets/img/contact.png"
import { withRouter } from "react-router";
import moment from "moment";
import { arabicContent } from "../../constants/index";
import './style.scss'
import line_seperator from "../../assets/img/line_seperator.svg"
import chat_with_us from "../../assets/img/chat_with_us.png"
import uiConfig from "../../uiConfig";
// import Smartbuy_new from "../../assets/img/smartbuy_new.png"

const lang = localStorage.getItem("language");

class Footer extends PureComponent {
  /* Other Render Functions */
  _renderTitle(title) {
    return <h5 className="ftr-sub">{title}</h5>;
  }

  routeToProfile = (activeItem) => {
    this.props.setActiveItem(activeItem);
    sessionStorage.setItem("currPage", activeItem);
    this.props.history.push("/home/profile", activeItem)
  }
  /* Main Render Functions */
  render() {
    return (
      <>
        {this.props.history.location.pathname !== "/login" &&
          <div className="col-md-12 footer" >
            <div className='footer-top' style={{background:uiConfig.config.whitelabel.footer.heading.color ,color : uiConfig.config.whitelabel.footer.foottext}}>
              {/* <span className='footer-top-content ml-15'>
                {lang === 'AR' ? arabicContent.aboutus : `About Us`}
              </span>
              <span><img src={line_seperator} /></span> */}
              <div>
                <span className='footer-top-content' onClick={() => { this.routeToProfile("faq") }}
                >
                  {lang === 'AR' ? arabicContent.faq : `FAQ's`}
                </span>
                <span className="line-sep" ><img src={line_seperator} /></span>
                <span className='footer-top-content'
                  onClick={() => { this.routeToProfile("termsAndCondition") }}
                >
                  {lang === 'AR' ? arabicContent.termAndCond : `Terms & Conditions`}
                </span>
                <span className="line-sep" ><img src={line_seperator} /></span>
                <span className='footer-top-content'
                  onClick={() => { this.routeToProfile("privacy") }}
                >
                  {lang === 'AR' ? arabicContent.privPolicy : `Privacy Policy`}
                </span>
                {/* <span className="line-sep" ><img src={line_seperator} /></span> */}
                {/* <span className='footer-top-content' onClick={() => { this.routeToProfile("customerSupport") }} > */}
                {/* <span className='footer-top-content'>
                {lang === 'AR' ? arabicContent.contactus+" " : `Customer Support `}
                </span> */}
              </div>
              <div>
                {/* <span >
                  <span><img src={adib_globe} /> </span>
                  <span className='footer-top-content cursor-default'>{ lang === 'AR' ? (` ADIB's  `)+arabicContent.internationalpresence : `ADIB's International Presence` }</span>
                </span> */}
              </div>
            </div>
            <div className='footer-inside'  style={{background:uiConfig.config.whitelabel.footer.background,color : uiConfig.config.whitelabel.footer.foottext}}>
              {/* <div className="footer-middle"> */}
             <div style={{display:"flex",justifyContent:"space-between"}}>
              <div className="footer-disc">{lang === 'AR' ? arabicContent.adbftrdisclaimer : `Disclaimer: Smart Buy is a platform for communication of offers extended by Merchants to HDFC bank's Customers. HDFC bank is only communicating the offers extended by Merchants to its Customers and not selling/rendering any of these products/services. HDFC bank is merely facilitating the payment to its Customers by providing the Payment Gateway Services. HDFC bank is neither guaranteeing nor making any representation of the same. HDFC bank is not responsible for sales/quality/features of the products/services under the offers.`}</div>
              
              {/* <span className='maya-logo cursor' onClick={() => { window.open('https://maya.ai/') }} > */}
                <div style={{padding: '39px 0 0 0'}}><Icons name="smartbuy" width={160} height={25} /></div>
                </div>
              {/* </span> */}
              {/* <div style={{paddingTop:"39px"}}><img src={Smartbuy_new} style={{width:"187px",height:"35px"}}/></div>
              </div> */}
              {/* <div className='site-content' >
                <span className="footer-disc" >
                  {lang === 'AR' ? arabicContent.ftrText2 : `Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE10+ at 1024 x 768 pixels resolution.`}
                </span>
              </div> */}
              
              <div className='footer-bottom'>
                <span className='rights-reserved' >
                  <span>
                    <span>{lang === 'AR' ? '' : `©`}</span>
                    {/* <span> {moment().format("YYYY")} </span> */}
                    <span>{lang === 'AR' ? arabicContent.adbCopyright : ` 2024 HDFC Smartbuy, Inc.`}</span>
                    {lang === 'AR' ? arabicContent.allright : `All rights reserved`} ·
                  </span>
                  <span >
                    <span className="cursor" onClick={() => {
                      this.routeToProfile("privacy")
                    }}>   {lang === 'AR' ? arabicContent.privacy : `Privacy`} · </span>
                    <span className="cursor" onClick={() => {
                      this.routeToProfile("termsAndCondition")
                    }}>   {lang === 'AR' ? arabicContent.terms : `Terms`}</span>
                      {/* <span>{lang === 'AR' ? arabicContent.sitemap : `Sitemap`}</span> */}
                  </span>
                </span>
                <span className='maya-logo cursor' onClick={() => { window.open('https://maya.ai/') }} >
                  <Icons name="mayatexthdfc" width={160} height={' '} />
                </span>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withRouter(Footer)