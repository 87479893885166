/**
 *
 * Disclaimer: Source code mentioned below is(are) Intellectual Property of
 * Crayon Data Holdings Limited (including its subsidiaries and affiliates).
 * Crayon Data Holdings Limited reserves right to own and control it the way
 * it may deem fit. You must refrain from use, access, read, modify, add or
 * delete, sell or use in any other package or programme pertaining to such
 * source code without explicit prior written approval of
 * Crayon Data Holding Limited. Breach of the same shall attract penalty as
 * applicable.
 *
 */

// @flow
import React, { Suspense, lazy, Component } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

//components
import routes from "./route_config";
import CommonLoader from "../components/Common_Loader";
import {
  getCity,
  getError,
  getCategory,
  homePageList,
  getRecentSearch,
  getTypeHead,
  getProfileDetails,
  updatePersonaldetail,
  getCampaignChoice,
  Login,
  randomTokenGen,
  getRecentCity,
  getPopularSearchTags,
  interaction,
  getCampaigns
} from "../service";

import PageBase from "../pages/PageBase";
import Footer from "../components/Footer";
import ADIB_Mobile_Footer from "../components/ADIB_Mobile_Footer";
import AFooter from "../components/AdibFooter";
import HFooter from "../components/HdfcFooter";
import Hdfc_Mobile_Footer from "../components/HDFC_Mobile_Footer";
import PageNotFoundComponent from "../components/PageNotFoundComponent";
import history from "./history";
import { tokenHeader } from "../constants";
import config from "../service/config";
import { accessTokenHelper } from "../helper/token";
import CookiePopupComponent from "../components/CookiePopup";
import eventBus from "../pages/helper";

const authPath = "/login";
const cookieConsent = localStorage.getItem("cookies_enabled");
let videoPopup = localStorage.getItem("video_popup");
const authed = () => localStorage.getItem(tokenHeader.TOKEN);
const Authed = () => localStorage.getItem("USERID");
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: sessionStorage.getItem("actMenu") || "home",
      campaignCardClicked: false,
      countryList: [],
      categoryList: [],
      homeList: null,
      homeListLoading: false,
      userData: null,
      reacentSearch: [],
      typeAhead: [],
      loadingBarProgress: 0,
      campaignCardClicked: false,
      activeItem: "myProfile",
      listLoad: true,
      rightMenuActive: "",
      recentLocation: [],
      popularSearch: [],
      openModal: false,
      userAuthed: false,
      videoModal: false,
      videoWidget: false
    };
  }

  async componentDidMount() {
    if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          localStorage.setItem("location-permission", true);
          localStorage.setItem(
            "currentLocation",
            JSON.stringify({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            })
          );
        },
        (error) => {
          localStorage.setItem("location-permission", false);
        }
      );
    }
    //Add class for Arabic Language Template
    if (localStorage.getItem("language") === "AR") {
      document.body.classList.add("arabic-body");
    }
    if (config.isLoginEnabled === "true") {
      if (authed()) {
        localStorage.setItem("tab", "home");
        // await this.getData()
      }
    } else {
      // const userToken=sessionStorage.getItem(tokenHeader.TOKEN);
      const userToken = localStorage.getItem(tokenHeader.TOKEN);
      if (userToken) {
        // sessionStorage.setItem(tokenHeader.TOKEN, userTokenLocal);
        //accessTokenHelper.set(userToken);
        localStorage.setItem(tokenHeader.TOKEN, userToken);
        localStorage.setItem("tab", "home");
        // this.getData()
      } else if (!userToken || userToken === null) {
        const token = randomTokenGen(16);
        // sessionStorage.setItem(tokenHeader.TOKEN, token);
        //  accessTokenHelper.set(token);
        localStorage.setItem(tokenHeader.TOKEN, token);
        localStorage.setItem("tab", "home");
        // this.getData()
      } else {
        // this.getData()
      }
      // await this.getDataForCampaign()
    }
  }

  componentDidUpdate() {
    // Fix for qual-5519 later to be checked
    // const pathnamedefault =
    //   typeof window !== "undefined" &&
    //   window.location.pathname.split("/").slice(1);
    //   console.log(pathnamedefault,"pathname")
    // if (pathnamedefault && pathnamedefault.includes("merchant")) {
    //   window.onpopstate = this.onBackButtonEvent;
    // }
  }

  onBackButtonEvent = (e) => {
    e.preventDefault();
    this._onChangeTab("home");
    sessionStorage.setItem("actMenu","home")
  }

  // login popup
  updateAuth = () => {
    if (Authed()) {
      this.setState({ openModal: false, userAuthed: true });
    } else {
      this.setState({ openModal: true, userAuthed: false });
    }
  };
  _openLogin = () => {
    this.setState({ openModal: true });
  };

  _closeLogin = (type) => {
    if (type === "submit") {
      if (localStorage.getItem("USERID")) {
        this.getData();
        this.interactionAPI(type);
        this.updateAuth();
        this.setState({ openModal: false });
        this.videoPopupAction("show");
      }
      return;
    }
    if (type === "merchant") {
      if (localStorage.getItem("USERID")) {
        this.interactionAPI(type);
        this.updateAuth();
        this.setState({ openModal: false });
        this.videoPopupAction("show");
      }
      return;
    }
    if (type === "Wishlist") {
      if (localStorage.getItem("USERID")) {
        localStorage.removeItem("interactionFrom");
        this.setActiveMenu(" ");
        this.updateAuth();
        this.setState({ openModal: false });
        this.videoPopupAction("show");
        history.push("/home/wishlisted");
      }
      return;
    }
    localStorage.removeItem("interactionType");
    localStorage.removeItem("interactionId");
    localStorage.removeItem("interactionFrom");
    this.updateAuth();
    this.setState({ openModal: false });
    this.videoPopupAction("show");
  };

  videoPopupAction = (action) => {
    // console.log("This is Video Popup");
    if (action == "show") {
      if (
        !videoPopup &&
        this.state.homeList &&
        this.state.homeList.length > 0 &&
        config.isVideoPopupEnabled
      ) {
        this.setState({ videoModal: true });
        localStorage.setItem("video_popup", true);
      }
    } else if (action == "widget") {
      this.setState({ videoModal: true, videoWidget: false });
    } else {
      this.setState({ videoModal: false, videoWidget: true });
    }
  };

  interactionAPI = (type) => {
    if (localStorage.getItem("interactionType") === "WISHLIST") {
      let interactionId = localStorage.getItem("interactionId");
      interaction("WISHLIST", "MERCHANT", interactionId)
        .then((res) => {
          if (type === "submit") {
            history.push("/home");
          } else if (type === "merchant") {
            window.location.reload();
          }
          localStorage.removeItem("interactionType");
          localStorage.removeItem("interactionId");
          localStorage.removeItem("interactionFrom");
          this.updateAuth();
          this.setState({ openModal: false });
        })
        .catch((err) => {
          if (err && err.data && err.data.status === 404) {
          } else {
            //    console.log(getError(err));
          }
          if (type === "submit") {
            history.push("/home");
          } else if (type === "merchant") {
            window.location.reload();
          }
          localStorage.removeItem("interactionType");
          localStorage.removeItem("interactionId");
          localStorage.removeItem("interactionFrom");
          this.updateAuth();
          this.setState({ openModal: false });
        });
    }
    if (localStorage.getItem("interactionType") === "CLAIM") {
      let interactionId = localStorage.getItem("interactionId");
      interaction("CLAIM", "OFFER", interactionId)
        .then((res) => {
          localStorage.removeItem("interactionType");
          localStorage.removeItem("interactionId");
          localStorage.removeItem("interactionFrom");
          this.updateAuth();
          //this.setState({ openModal: false });
          window.location.reload();
          localStorage.setItem("openClaimModal", "true");
        })
        .catch((err) => {
          if (err && err.data && err.data.status === 404) {
          } else {
            //    console.log(getError(err));
          }
          localStorage.removeItem("interactionType");
          localStorage.removeItem("interactionId");
          localStorage.removeItem("interactionFrom");
          this.updateAuth();
          //this.setState({ openModal: false });
          window.location.reload();
          localStorage.setItem("openClaimModal", "false");
        });
    }
  };

  getData = () => {
    this.setState({
      homeListLoading: true,
      listLoad: true
    });
    let data = {
      countryList: [],
      categoryList: [],
      reacentSearch: [],
      userData: null
    };

    // Get City
    getCity()
      .then((res) => {
        if (res.length > 0) {
          data.countryList = res;
          this.setState({
            countryList: res
          });
          let country = JSON.parse(localStorage.getItem("selectCountry"))
            ? JSON.parse(localStorage.getItem("selectCountry"))
            : res[0];
            getCampaigns()
            .then(res => {
              if (res && res.campaigns && res.campaigns.length > 0) {
                eventBus.dispatch('updatebanner',res.campaigns )
                } else {
                  eventBus.dispatch('updatebanner',[] )
                }
            })
            .catch((err) => {
               console.log("campaigns", err);
            })
          return this.getHomeList(country);
        }
      })
      .catch((err) => {
        let country = JSON.parse(localStorage.getItem("selectCountry"))
          ? JSON.parse(localStorage.getItem("selectCountry"))
          : {};
        this.setState({
          homeListLoading: false
        });
        return this.getHomeList(country);
      });

    // getProfileDetails()
    //   .then((userInfo) => {
    //     if (userInfo) {
    //       this.setState({
    //         userData: userInfo
    //         // homeListLoading:false
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     //  console.log(getError(err));
    //     // this.setState({
    //     //   homeListLoading:false
    //     // })
    //   });
    //  getRecentSearch().then((resData) => {
    //   if (resData && resData.response && resData.response.length > 0) {
    //     this.setState({
    //       reacentSearch:resData.response
    //     })
    //     // data.reacentSearch = resData.response;
    //   }
    // }).catch((err) => {
    //   console.log(getError(err));

    // });
  };

  getDataForCampaign = () => {
    let data = {
      countryList: [],
      categoryList: [],
      reacentSearch: [],
      userData: null
    };

    // Get City
    getCity()
      .then((res) => {
        if (res.length > 0) {
          data.countryList = res;
          this.setState({
            countryList: res
          });
          this.setState({
            categoryList: JSON.parse(sessionStorage.getItem("catList"))
          });
          // getCategory("categories")
          //   .then((result) => {
          //   if (result.length > 0) {
          //     this.setState({
          //       categoryList:result
          //     },()=>sessionStorage.setItem('catList',JSON.stringify(result)))

          //   }}).catch((err) => {
          //   //  console.log(getError(err));
          // });
        }
      })
      .catch((err) => {
        // console.log(getError(err));
      });

    // getProfileDetails()
    //   .then((userInfo) => {
    //     if (userInfo) {
    //       this.setState({
    //         userData: userInfo
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     //  console.log(getError(err));
    //   });
  };

  setActiveMenu = (activeMenu) => {
    this.setState({ activeMenu: activeMenu }, () => {
      let country =JSON.parse(localStorage.getItem("selectCountry"));
      if (activeMenu !== "") this.getHomeList(country);
    });
  };

  setRightMenuActive = (mnu) => {
    this.setState({ rightMenuActive: mnu });
  };

  getHomeList = (opt) => {
    this.setState({ listLoad: true });

    const { activeMenu } = this.state;

    // this.setState({activeMenu:"home"})
    let category = null;
    if (activeMenu === "travel") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else if (activeMenu === "apparel and accessories") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else if (activeMenu === "miscellaneous stores") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else if (activeMenu === "retail stores") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else if (activeMenu === "entertainment") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else if (activeMenu === "personal service providers") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else if (activeMenu === "dining") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else if (activeMenu === "all offers") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else if (activeMenu !== "home") {
      category = {
        value: activeMenu,
        label: "Recommended"
      };
    } else {
      category = {
        value: "all",
        label: "Recommended"
      };
    }
    localStorage.setItem("selectFirstCountry", JSON.stringify(opt));
    localStorage.setItem("selectCountry", JSON.stringify(opt)); // Temporary local storage
    let selectedCity = opt;
    this.setState({ homeListLoading: true });
    if (category.value !== "all offers") {
      localStorage.removeItem("api_status_home");
      homePageList(category, selectedCity, null)
        .then((res) => {
          if (res) {
            this.setState({
              homeList: res.choices,
              homeListLoading: false,
              listLoad: false
            });
            if (res.categories && res.categories !== null && activeMenu !== "visa") {
              this.setState(
                {
                  categoryList: res.categories
                },
                () =>
                  localStorage.setItem(
                    "catList",
                    JSON.stringify(res.categories)
                  )
              );
            } else {
              this.setState({
                categoryList: JSON.parse(localStorage.getItem("catList"))
              });
            }
            if (localStorage.getItem("USERID") && !videoPopup) {
              this.videoPopupAction("show");
            }
          } else {
            this.setState({
              homeList: [],
              homeListLoading: false,
              listLoad: false
            });
          }
        })
        .catch((err) => {
          if (
            ![400, 402, 404, 500].includes(
              Number(localStorage.getItem("api_status_home"))
            )
          ) {
            //localStorage.setItem("api_status_home", "api_downtime");
          }
          //  console.log(getError(err));
          this.setState({
            homeList: [],
            homeListLoading: false
          });
        }).finally(() => {
          getRecentSearch()
          .then((resData) => {
            if (resData && resData.response && resData.response.length > 0) {
              this.setState({
                reacentSearch: resData.response
              });
              // data.reacentSearch = resData.response;
            } else {
              this.setState({
                reacentSearch: []
              });
            }
          })
          .catch((err) => {
            //  console.log(getError(err));
          });

        getPopularSearchTags()
          .then((res) => {
            if (res) {
              localStorage.setItem("popular-search", Object.keys(res));
              this.setState({
                popularSearch: Object.keys(res)
              });
            }
          })
          .catch((err) => {
            //  console.log(getError(err);
          });
        })
    } else {
      this.setState({
        categoryList: JSON.parse(localStorage.getItem("catList"))
      });
    }

    getRecentCity()
      .then((data) => {
        if (data.length > 0) {
          this.setState({ recentLocation: data });
        }
      })
      .catch((err) => {
        this.setState({
          homeListLoading: false
        });
      });
  };

  getCampaignChoice = (campaignId) => {
    this.setState({ campaignCardClicked: true });

    getCampaignChoice(campaignId)
      .then((res) => {
        if (res) {
          if (res.choices) {
            this.setState({
              activeMenu: "home",
              homeList: res.choices,
              homeListLoading: false
            });
          } else {
            this.setState({
              activeMenu: "home",
              homeList: [],
              homeListLoading: false
            });
          }
        } else {
          this.setState({
            activeMenu: "home",
            homeList: [],
            homeListLoading: false
          });
        }
      })
      .catch((err) => {
        //  console.log(getError(err));
        this.setState({
          activeMenu: "home",
          homeList: [],
          homeListLoading: false
        });
      });
  };

  _onChangeTab = (activeMenu) => {
    this.setState({ homeListLoading: true });
    this.setState({ activeMenu });
    this.setState({ campaignCardClicked: false });
  };

  setActiveItem = (activeItem) => {
    this.setState({ activeItem });
  };

  setcategoryList = (categoryList) => {
    this.setState({ categoryList });
  };

  render() {
    return (
      <>
        <Router>
        {authed() && (
                <PageBase
                  _openLogin={this._openLogin}
                  userAuthed={this.state.userAuthed}
                  _closeLogin={this._closeLogin}
                  updateAuth={this.updateAuth}
                  openModal={this.state.openModal}
                  _onChangeTab={this._onChangeTab}
                  setActiveMenu={this.setActiveMenu}
                  setActiveItem={this.setActiveItem}
                  setRightMenuActive={this.setRightMenuActive}
                  // getTypeHeadItem={this.getTypeHeadItem}
                  activeItem={this.state.activeItem}
                  getHomeList={this.getHomeList}
                  getRecentSearchData={this.getData}
                  typeAhead={this.state.typeAhead}
                  rightActiveMenu={this.state.rightActiveMenu}
                  history={history}
                  {...this.state}
                  {...this.props}
                />
              )}
         
            <Suspense
              fallback={
                <div>
                  <CommonLoader />
                </div>
              }
            >
         <Switch>
              {routes.map((route, i) => (
                <Route
                  key={route.key || i}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => {
                    if (config.isLoginEnabled === "true") {
                      if (route.authentication && route.path !== authPath) {
                        if (authed()) {
                          return (
                            <route.component
                              _openLogin={this._openLogin}
                              getData={this.getData}
                              setActiveMenu={this.setActiveMenu}
                              getDataForCampaign={this.getDataForCampaign}
                              getCampaignChoice={this.getCampaignChoice}
                              setActiveItem={this.setActiveItem}
                              history={history}
                              location={history.location}
                              campaignCardClicked={
                                this.state.campaignCardClicked
                              }
                              activeMenu={this.state.activeMenu}
                              homeListLoading={this.state.homeListLoading}
                              listLoad={this.state.listLoad}
                              _onChangeTab={this._onChangeTab}
                              setcategoryList={this.setcategoryList}
                              {...this.state}
                              {...props}
                              routes={route.routes}
                              videoPopup={videoPopup}
                            />
                          );
                        }
                        if (!authed()) return <Redirect to="/login" />;
                      }
                      if (!route.authentication) {
                        if (
                          route.name === "campaigns" ||
                          route.name === "users" || route.name === "festivaloffers"
                        ) {
                          return <Redirect to="/" exact />;
                        } else if (route.path === authPath) {
                          if (authed()) return <Redirect to="/" />;
                          if (!authed())
                            return (
                              <route.component
                                _openLogin={this._openLogin}
                                getData={this.getData}
                                setActiveMenu={this.setActiveMenu}
                                getDataForCampaign={this.getDataForCampaign}
                                getCampaignChoice={this.getCampaignChoice}
                                setActiveItem={this.setActiveItem}
                                setHomeList={this.setHomeList}
                                history={history}
                                location={history.location}
                                campaignCardClicked={
                                  this.state.campaignCardClicked
                                }
                                activeMenu={this.state.activeMenu}
                                homeListLoading={this.state.homeListLoading}
                                listLoad={this.state.listLoad}
                                _onChangeTab={this._onChangeTab}
                                setcategoryList={this.setcategoryList}
                                {...this.state}
                                {...props}
                              />
                            );
                        } else if (route.default) {
                          if (authed()) return <Redirect to="/" />;
                          if (!authed()) return <Redirect to="/login" />;
                        } else {
                          return (
                            <route.component
                              getData={this.getData}
                              _openLogin={this._openLogin}
                              getDataForCampaign={this.getDataForCampaign}
                              getCampaignChoice={this.getCampaignChoice}
                              setActiveItem={this.setActiveItem}
                              setActiveMenu={this.setActiveMenu}
                              setHomeList={this.setHomeList}
                              history={history}
                              location={history.location}
                              campaignCardClicked={
                                this.state.campaignCardClicked
                              }
                              activeMenu={this.state.activeMenu}
                              homeListLoading={this.state.homeListLoading}
                              listLoad={this.state.listLoad}
                              _onChangeTab={this._onChangeTab}
                              setcategoryList={this.setcategoryList}
                              {...this.state}
                              {...props}
                            />
                          );
                        }
                      }
                    } else {
                      if (route.path === authPath) return <Redirect to="/" />;
                      else
                        return (
                          <route.component
                            _openLogin={this._openLogin}
                            getData={this.getData}
                            getDataForCampaign={this.getDataForCampaign}
                            getCampaignChoice={this.getCampaignChoice}
                            setActiveItem={this.setActiveItem}
                            setActiveMenu={this.setActiveMenu}
                            setRightMenuActive={this.setRightMenuActive}
                            history={history}
                            location={history.location}
                            campaignCardClicked={this.state.campaignCardClicked}
                            activeMenu={this.state.activeMenu}
                            _onChangeTab={this._onChangeTab}
                            setcategoryList={this.setcategoryList}
                            videoPopupAction={this.videoPopupAction}
                            {...this.state}
                            {...props}
                          />
                        );
                    }
                  }}
                />
              ))}
           <Route
                path="*"
                render={() => {
                  setTimeout(() => {
                    history.push("/home");
                  }
                  , 5000);
                  return (
                    <PageNotFoundComponent
                      title="The web address you entered is not a functioning of the page"
                      description={
                        <span>
                          We will re-direct you to the home page in the next 5 secs.
                          If after 5 secs, you are still on this page, please{" "}
                          <span
                            style={{
                              color: "blue",
                              fontWeight: 500,
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              history.push("/home");
                            }}
                          >
                            Click here.
                          </span>
                        </span>
                      }
                    />
                  );
                }}
              />
              </Switch>
              {authed() && config.clientName === "ADIB" ? (
                <>
                  {" "}
                  <AFooter
                    history={history}
                    setActiveItem={this.setActiveItem}
                  />{" "}
                  <ADIB_Mobile_Footer
                    history={history}
                    setActiveItem={this.setActiveItem}
                  />{" "}
                </>
              ) : config.clientName === "HDFC" ? (
                <>
                  {" "}
                  {cookieConsent ? null : (
                    <CookiePopupComponent
                      history={history}
                      setActiveItem={this.setActiveItem}
                    />
                  )}
                  <HFooter
                    history={history}
                    setActiveItem={this.setActiveItem}
                  />{" "}
                  <Hdfc_Mobile_Footer
                    history={history}
                    setActiveItem={this.setActiveItem}
                  />{" "}
                </>
              ) : (
                <>
                  <Footer
                    history={history}
                    setActiveItem={this.setActiveItem}
                  />
                </>
              )}
            </Suspense>
        </Router>
      </>
    );
  }
}

export default Routes;
